// Constant that will use in future
export const constant = {
    BASE_URL: "https://rodeo.tekstaging.com/api/v1/",
    DEVICE_TYPE: "web",
    DEVICE_TOKEN: "hadouken",
    LOCAL_STORAGE_TOKEN: "rodeoToken",
    LOCAL_STORAGE_USER: "rodeoUser"
}

// API end points
export const apiEndPoints = {
    login: "login",
    logout: "logout",
    forgot_password: "forget-password",
    verify_reset_code: "verify-reset-code",
    reset_password: "reset-password",
    change_password: "change-password",
    dashboard_post: "posts",
    notifications: "notifications",
    pages: "pages",
    team_user: "team-users",
    me: "me"

}