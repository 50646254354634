import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

// Get All Team Users

export const GetAllTeamUsers = async () => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

// Get All Team Users Weekly

export const getTemUserWeekly = async () => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?weekly_points=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


// Get All Team Users Monthly

export const getTemUserMonthly = async () => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.team_user}?monthly_points=1`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}