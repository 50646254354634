import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import SettingNavigation from '../components/dashboard/SettingNavigation';
import { getSecondaryPageData } from '../services/pages';
import { success, error } from '../constants/msg';
import parse from "html-react-parser";
import Loader from '../components/loader/Loader';

const TermsAndConditionsPage = () => {
    const [termConditionData, settermConditionData] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getPageData = async () => {
            try {
                let response = await getSecondaryPageData();
                settermConditionData(response.data.data);
                setLoading(true)
            } catch (e) {
                error(e.response.data.message)
            }
        }

        getPageData();
    })
    return (
        <div className='terms-and-conditions-page pb-3'>
            <Row>
                <Col xs={12} className="d-block d-md-none mt-4">
                    <h5 className='text-yellow'>SETTINGS</h5>
                </Col>

                <SettingNavigation />

                <Col xs={12} lg={12} xl={9} className="mt-4">
                    <div className='box p-4'>
                        <h6 className='mb-4'> {termConditionData.length > 0 && parse(termConditionData[0].title)}</h6>
                        {loading ? (termConditionData.length > 0 && parse(termConditionData[0].content)) : (<Loader />)}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TermsAndConditionsPage