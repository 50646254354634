import React, { useState } from 'react'
import { Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { ForgotPassword } from "../../services/auth"

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const onSubmit = async (data) => {
        const { email } = data;
        try {
            const response = await ForgotPassword(email);
            success(response.data.message)
            setTimeout(() => {
                navigate("/verification", { state: { email: email, replace: false } })
            }, 1500)
        }
        catch (e) {
            error(e.response.data.message)
        }
        // success("Email has been sent")

    }

    return (
        <section className='forgot-password-section mt-5'>
            <ToastContainer />
            <span className='d-block text-center mb-5'>Enter your email address below. We'll look for your account and send you a password reset email.</span>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-3'>
                    <Form.Control
                        name="email"
                        type="email"
                        placeholder="Email *"
                        {...register("email",
                            {
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            })
                        }
                    />
                </InputGroup>
                {errors.email && <small className='text-red'>{errors.email.message}</small>}

                <div className='text-center'>
                    <Button type="submit" className="w-100 mt-5">SUBMIT</Button>
                </div>
            </Form>
        </section>
    )
}

export default ForgotPasswordForm