import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

//pending post 
export const PendingPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&sortedBy=asc&paginate=25&page=1&user_id=${user_ID}&status=pending`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}

//get upcoming scheduled Post 

export const UpcomingPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&sortedBy=desc&paginate=6&page=1&user_id=${user_ID}&status=approved`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//get single post by ID

export const GetSinglePost = async (id) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}/${id}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}


