import React, { useState } from 'react'
import { Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { ResetPassword } from "../../services/auth";

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(location.state.email);
    const [otp, setOtp] = useState(location.state.otp);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const password = watch("password");

    const onSubmit = async (data) => {
        const { password, newPassword } = data
        try {
            const response = await ResetPassword(email, otp, password, newPassword);
            success(response.data.message)
            setTimeout(() => {
                navigate("/")
            }, 1500)

        } catch (error) {
            error(error.response.data.message)
        }

    }

    return (
        <section className='reset-password-section mt-5'>
            <ToastContainer />
            <span className='d-block text-center mb-5'>Please enter a new password different <br /> from your old one</span>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-3'>
                    <Form.Control
                        name="password"
                        className="password-input"
                        placeholder="Password *"
                        type={!showPassword ? "password" : "text"}
                        {...register("password",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                },
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.password && <small className='text-red'>{errors.password.message}</small>}

                <InputGroup className='mt-3'>
                    <Form.Control
                        name="confirmPassword"
                        className="password-input"
                        placeholder="Confirm Password *"
                        type={!showConfirmPassword ? "password" : "text"}
                        {...register("confirmPassword",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                },
                                validate: (value) =>
                                    value === password || "The Passwords do not match"
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showConfirmPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.confirmPassword && <small className='text-red'>{errors.confirmPassword.message}</small>}


                <div className='text-center'>
                    <Button type="submit" className="w-100 mt-5">RESET PASSWORD</Button>
                </div>
            </Form>
        </section>
    )
}

export default ResetPasswordForm