import React, { useContext, useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import EditPostPopup from '../components/dashboard/EditPostPopup';
import SinglePostManagementCard from '../components/dashboard/SinglePostManagementCard';
import { GetSinglePost } from '../services/dashboard';
import { success, error } from "../constants/msg";

const SinglePostManagementPage = () => {
    const { id } = useParams();
    const [handleEditPostPopup, setHandleEditPostPopup] = useState(false);
    const [singlePost, setSinglePost] = useState();

    {console.log(singlePost,"sinaodf")}

    const handleClickEditPostPopup = () => {
        setHandleEditPostPopup(!handleEditPostPopup)
    }


    useEffect(() => {
        const getSinglePostById = async () => {
            try {
                const response = await GetSinglePost(id);
                setSinglePost(response.data.data);

            } catch (e) {
                error(e.response.data.message)
            }
        }
        getSinglePostById();
    }, []);

    return (
        <>
            {handleEditPostPopup === true && <EditPostPopup handleClickEditPostPopup={handleClickEditPostPopup} />}
            <div className="post-management-page">
                <Row className="mt-4">
                    <SinglePostManagementCard handleClickEditPostPopup={handleClickEditPostPopup} singlePost={singlePost} />
                </Row>
            </div>
        </>
    )
}

export default SinglePostManagementPage