import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

// Delete post
export const DeletePost = async (post_ID) => {
    const resp = await axios.delete(`${constant.BASE_URL + apiEndPoints.dashboard_post}/${post_ID}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }
    );
    return resp
}


//All Post 
export const AllPost = async (user_ID) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.dashboard_post}?orderBy=id&sortedBy=asc&paginate=25&page=1&user_id=${user_ID}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}