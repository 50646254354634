import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';


export const SetTokenLocalStorage = (token) => {
    localStorage.setItem(constant.LOCAL_STORAGE_TOKEN, token)
}
export const SetAuthUserLocalStorage = (user) => {
    localStorage.setItem(constant.LOCAL_STORAGE_USER, JSON.stringify(user))
}



export const GetTokenLocalStorage = () => {
    if (localStorage.getItem(constant.LOCAL_STORAGE_TOKEN)) {
        return localStorage.getItem(constant.LOCAL_STORAGE_TOKEN)
    }
    else {
        return null;
    }
}
export const GetAuthUserLocalStorage = () => {
    if (localStorage.getItem(constant.LOCAL_STORAGE_USER)) {
        return JSON.parse(localStorage.getItem(constant.LOCAL_STORAGE_USER))
    }
    else {
        return null;
    }
}
export const EmptyLocalStorage = () => {
    console.log("empty stroage run")
    localStorage.removeItem(constant.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(constant.LOCAL_STORAGE_USER);
}

export const Login = async (email, password, device_type, device_token) => {
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.login, { email, password, device_type, device_token })
    return resp;
}

export const Logout = async () => {
    const resp = await axios.post(constant.BASE_URL + apiEndPoints.logout, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
    return resp
}

//Forgot Password

export const ForgotPassword = async (email) => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.forgot_password}?email=${email}`);
    return resp
}

//Verification OTP Code

export const VerficationCode = async (otp_code) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.verify_reset_code}?verification_code=${otp_code}`);
    return resp;
}

//Reset Password 

export const ResetPassword = async (email, otp, password, newPassword) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.reset_password}?email=${email}&verification_code=${otp}&password=${password}&password_confirmation=${newPassword}`);
    return resp
}

//Update Password

export const UpdatePassword = async (currentPassword, newPassword, confirmPassword) => {
    const resp = await axios.post(`${constant.BASE_URL + apiEndPoints.change_password}?current_password=${currentPassword}&password=${newPassword}&password_confirmation=${confirmPassword}`, null, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    })
return resp
}