import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

//Privacy Policy
export const getSecondaryPageData = async () => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.pages}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

