import React, { useState, useEffect, useContext } from 'react'
import { Route, Navigate, Outlet } from "react-router";

import { constant } from './utlis/constants'


const ProtectedRoute = () => {
    const token = localStorage.getItem(constant.LOCAL_STORAGE_TOKEN)
    return (
        true ? <Outlet /> : <Navigate to="/" />
    )
}

export default ProtectedRoute;