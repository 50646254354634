import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import Assets from '../constants/images'
import { DoneIcon, FireIcon, StarIcon } from '../constants/svgs'
import LeaderboardCard from '../components/dashboard/LeaderboardCard';
import { GetAllTeamUsers, getTemUserWeekly } from "../services/teamsUser";
import { success, error } from "../constants/msg";
import Loader from '../components/loader/Loader';

const LeaderboardPage = () => {
    const [loading, setLoading] = useState(false)
    const [userList, SetUserList] = useState({
        allUser: true,
        monthlyUsers: false,
        weeklyUsers: false
    });

    const [userListData, SetUserListData] = useState();

    let { allUser, monthlyUsers, weeklyUsers } = userList;

    useEffect(() => {

        //Get All User Function
        const getTeamUser = async () => {
            try {
                let response = await GetAllTeamUsers();
                SetUserListData(response.data.data)
                console.log(response, "response")
                setLoading(true)

            } catch (e) {
                error(e.response.data.message)
            }
        }

        // Get All User Weekly
        const TemUserWeekly = async () => {
            try {
                let response = await getTemUserWeekly();
                SetUserListData(response.data.data)
                console.log(response, "response")

            } catch (e) {
                error(e.response.data.message)
            }
        }

        // Get All User Monthly
        const getTemUserMonthly = async () => {
            try {
                let response = await getTemUserMonthly();
                SetUserListData(response.data.data)
                console.log(response, "response")

            } catch (e) {
                error(e.response.data.message)
            }
        }

        if (allUser) {
            getTeamUser()
        }
        if (weeklyUsers) {
            TemUserWeekly()
        }
        if (monthlyUsers) {
            getTemUserMonthly()
        }



    }, [allUser, monthlyUsers, weeklyUsers])

    console.log(userListData, "userListData")


    return (
        <div className='leaderboard-page'>
            <Row className="mt-4">
                <Col xs={12} className="mb-4">
                    <h5 className='text-yellow d-block d-md-none'>LEADERBOARD</h5>
                    <h6>Add and Remove Your Social Media Accounts</h6>
                </Col>

                <Col xl={4} lg={4} className="mb-4">
                    <div className="left-container p-4">
                        <img className='w-100' src={Assets.BrandIconRectangle} alt="BrandIcon" />
                        <div className='mt-4'>
                            <p>KOTO Brand.co</p>
                            <div className='mb-2'>
                                <DoneIcon />
                                <h6 className='d-inline-block ms-2'>1205 Success Posts</h6>
                            </div>

                            <div>
                                <FireIcon />
                                <h6 className='d-inline-block ms-2'>1500</h6>
                            </div>
                        </div>
                    </div>
                </Col>

                <Col xl={6} lg={6} className="mb-4 mb-lg-0">
                    <div className="right-container">
                        <div className="">
                            <div className='d-block leaderboard-tabs'>
                                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example" className="py-2 pe-4">
                                    <Tab eventKey="all" title="All" onClick={() => {
                                        SetUserList({
                                            allUser: true,
                                            monthlyUsers: false,
                                            weeklyUsers: false
                                        })
                                    }}>

                                        <div className="leaderboard-card-container">
                                            {loading ? (userListData?.length > 0 && userListData?.map((data, key) => {
                                                return <LeaderboardCard cardData={data} key={key} index={key} />
                                            })) : (<Loader />)}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="weekly" title="Weekly" onClick={() => {
                                        SetUserList({
                                            allUser: false,
                                            weeklyUsers: true,
                                            monthlyUsers: false,
                                        })
                                    }}>
                                        <div className="leaderboard-card-container">
                                            {loading ? (userListData?.length > 0 && userListData?.map((data, key) => {
                                                return <LeaderboardCard cardData={data} key={key} index={key} />
                                            })) : (<Loader />)}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="monthly" title="Monthly" onClick={() => {
                                        SetUserList({
                                            allUser: false,
                                            weeklyUsers: false,
                                            monthlyUsers: true,
                                        })
                                    }}>
                                        <div className="leaderboard-card-container">
                                            {loading ? (userListData?.length > 0 && userListData?.map((data, key) => {
                                                return <LeaderboardCard cardData={data} key={key} index={key} />
                                            })) : (<Loader />)}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default LeaderboardPage