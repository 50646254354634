import React, { useState, useEffect, useContext } from 'react'
import { Button, Carousel, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { FaTimes, FaCheck } from "react-icons/fa"
import Assets from '../../constants/images'
import { AddUserIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, TwitterIcon, YoutubeIcon, YoutubeIconWithoutBg } from '../../constants/svgs'
import 'react-times/css/material/default.css';
import 'react-times/css/classic/default.css';
import { Controller, useForm } from "react-hook-form";
import { SPECIAL_CHARACTER_ARRAY, SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS, VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import DateTimePicker from 'react-datetime-picker';
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Select from 'react-select'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { PendingPostData } from '../../App'

//Services Importer
import { DeletePost } from "../../services/posts";
import { PendingPost } from "../../services/dashboard";

const PendingPostPopup = (props) => {
    const [pendingPost, setPendingPost] = useContext(PendingPostData);
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const [value, onChange] = useState(new Date());
    const [showLocationPickerModal, setShowLocationPickerModal] = useState(false);
    const [uploadedImage, setUploadedImage] = useState([]);
    const { register, handleSubmit, reset, watch, control, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    let markersList = [
        { lat: 23.024000, lng: 72.580276 },
    ]
    let [markers, setMarkers] = useState(markersList);

    const mapStyles = {
        position: "relative",
        width: "100%",
        height: "100%"
    };

    let onMarkerDragEnd = (coord, index, markers) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        markers[index] = { lat, lng };
        setMarkers(markers);
    }

    let myMarkers = markers && Object.entries(markers).map(([key, val]) => (
        <Marker key={key} id={key} position={{
            lat: val.lat,
            lng: val.lng
        }}
            draggable={true}
            onDragend={(t, map, coord) => onMarkerDragEnd(coord, key, markers)}
        />
    ))

    const handleImageUpload = (e) => {
        setUploadedImage([...uploadedImage, ...e.target.files])
    }

    const deleteImage = (i) => {
        let temp = [...uploadedImage]
        temp.splice(i, 1)
        setUploadedImage(temp)
    }

    const onSubmit = (data) => {
        console.log(data);
        reset();
        props.setVisible(false)
        setShowLocationPickerModal(false)
    }

    useEffect(() => {
        // setValue("recuringTime", value)
        setValue("image", uploadedImage)
    }, [value, uploadedImage]);




    useEffect(() => {
        reset({
            caption: props?.popupData?.text,
            location: props?.popupData?.location,
            tagPeople: props?.popupData?.tag
        });
        setMarkers([
            { lat: props?.popupData?.latitude, lng: props?.popupData?.longitude }
        ]);

        onChange(props?.popupData?.schedule_time)
        setUploadedImage(props?.popupData?.media)
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setMarkers([
                    { lat: position.coords.latitude, lng: position.coords.longitude }
                ])
            });
        }
    }, []);

    //Delete Post function
    const handleDelete = () => {
        const { id, user_id } = props?.popupData
        DeletePost(id).then((resp) => {
            success("Post has been deleted");
            PendingPost(user_id).then((response) => {
                setPendingPost(response.data.data)
            })
        })
    }




    return (
        <div>
            <ToastContainer />
            <Modal
                className='location-picker-modal'
                show={showLocationPickerModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between p-3  border-bottom">
                        <h6 className='p-0 m-0'>Select Your Location</h6>
                        <div onClick={() => setShowLocationPickerModal(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <Form encType='multipart/form-data' onSubmit={handleSubmit(onSubmit)}>
                        <Map
                            google={props.google}
                            zoom={14}
                            style={mapStyles}
                            initialCenter={
                                {
                                    lat: markers[0].lat,
                                    lng: markers[0].lng
                                }
                            }
                        >
                            {myMarkers}
                        </Map>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                className='pending-post-modal'
                show={props.visible}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="header d-flex w-100 align-items-center justify-content-between pb-3 mb-3 border-bottom">
                        <h6 className='p-0 m-0'>Pending Post</h6>
                        <div onClick={() => props.setVisible(false)} className="d-flex align-items-center justify-content-center text-center fa-times"><FaTimes /></div>
                    </div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='avatar-container d-flex align-items-center mb-3'>
                            <img src={props?.popupData?.user?.details?.image_url} alt="Avatar" class="rounded-circle" />
                            <h6 className='d-inline-block p-0 m-0 ms-2'>{props?.popupData?.user?.name}</h6>
                        </div>

                        <div>
                            <InputGroup className='mt-2'>
                                <Form.Control
                                    name="caption"
                                    placeholder="Add a caption"
                                    className="pt-3"
                                    as="textarea"
                                    rows={3}
                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    {...register("caption",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.CAPTION_MAX,
                                                message: VALIDATIONS_TEXT.CAPTION_MAX
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.caption && <small className='text-red'>{errors.caption.message}</small>}

                            <InputGroup className='mt-2'>
                                <Form.Control
                                    onClick={() => setShowLocationPickerModal(true)}
                                    name="location"
                                    placeholder="Add a location"
                                    className="input-icon input-icon-location"
                                    onKeyDown={(e) => (SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) || e.key === "0" || Number(e.key)) && e.preventDefault()}
                                    {...register("location",
                                        {
                                            maxLength: {
                                                value: VALIDATIONS.LOCATION_MAX,
                                                message: VALIDATIONS_TEXT.LOCATION_MAX
                                            },
                                        })
                                    }
                                />
                            </InputGroup>
                            {errors.location && <small className='text-red'>{errors.location.message}</small>}
                            {/* User that are tag */}
                            {/* <InputGroup className='mt-2'>
                                <Select onChange={(e) => setValue("addPeople", e)} className='w-100 custom-select-input' isMulti options={options} />
                            </InputGroup> */}

                            <div className="pending-post-label mt-2">
                                <div className="pending-post-wrap d-flex align-items-center">
                                    <div className='pending-post-icon icon-addPeople d-flex align-items-center'></div>
                                    <div className="pending-post-content d-flex align-items-center">
                                        {props?.popupData?.tags.length > 0 ? props?.popupData?.tags?.slice(0, 3).map((v, i) => (
                                            <span key={i}>
                                                {v.user_name},
                                            </span>
                                        )) : <p className='m-0'>No user has been tag</p>}
                                        {props?.popupData?.tags.length > 1 && <div className='cutom-number-div d-flex align-items-center justify-content-center text-center'>
                                            <span >{props?.popupData?.tags.length} others</span>
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            <InputGroup className='mt-2'>
                                <Form.Control
                                    readOnly
                                    placeholder="Added Media"
                                    className="bg-none input-icon input-icon-addImage"
                                />
                            </InputGroup>

                            <div className="mb-3 d-flex flex-row ">
                                {/* <input
                                    onChange={(e) => handleImageUpload(e)}
                                    className={`form-control custom-file-input ${uploadedImage.length > 0 && "mt-15"} me-3`}
                                    name="image"
                                    id="img"
                                    type="file"
                                    multiple
                                    accept=".png, .jpeg, .jpg, .mp4"
                                /> */}

                                <Swiper
                                    // navigation
                                    modules={[Navigation]}
                                    spaceBetween={10}
                                    breakpoints={{
                                        200: {
                                            slidesPerView: 2,
                                        },
                                        300: {
                                            slidesPerView: 3,
                                        },
                                        640: {
                                            slidesPerView: 5,
                                        },
                                        768: {
                                            slidesPerView: 6,
                                        },
                                        992: {
                                            slidesPerView: 8,
                                        },
                                        1200: {
                                            slidesPerView: 10,
                                        },
                                        1300: {
                                            slidesPerView: 10,
                                        },
                                    }}
                                >
                                    {
                                        uploadedImage.length > 0 ?
                                            uploadedImage.map((v, i) => {
                                                // let url = URL.createObjectURL(v?.url)
                                                return (
                                                    <SwiperSlide>
                                                        <div className="box">
                                                            {/* <div className='icon'>
                                                            <FaTimes onClick={() => deleteImage(i)} />
                                                        </div> */}
                                                            {v.type.toLowerCase() === "image" ? <img src={v?.url} alt="PostImage1" /> :
                                                                <video width="100%" height="100%" controls={false} >
                                                                    <source src={v.url} type="video/mp4" />
                                                                </video>
                                                            }

                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            }) :
                                            <p>No Image added</p>
                                    }
                                </Swiper>
                            </div>

                            {/* <Controller
                                name="recuringTime"
                                control={control}
                                defaultValue={null}
                                render={
                                    () => (
                                        <DateTimePicker onChange={onChange} value={value} />
                                    )
                                }
                            />
                            {value === null && <small className='text-red'>{VALIDATIONS_TEXT.DATE_TIME_REQUIRED}</small>}

                            <InputGroup className='mt-2'>
                                <Form.Control readOnly placeholder="Select Platform(s)" className="bg-none input-icon input-icon-shareIcon" />
                            </InputGroup> */}

                            <div className='socialCheckbox d-flex'>
                                {props?.popupData?.platforms?.map((v, i) => (
                                    <div key={i}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked
                                                id={v.name}
                                                className=''
                                                {...register("socialPlatform", {
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.SOCIAL_PLATFORM_REQUIRED
                                                    },
                                                })}
                                            />
                                            <label htmlFor={v.name} className={`d-flex align-items-center justify-content-center text-center label-${v.name.toLowerCase()}`}>
                                                {v.id == 1 ? <FacebookIcon /> : null}
                                                {v.id == 2 ? <TwitterIcon /> : null}
                                                {v.id == 3 ? <InstagramIcon /> : null}
                                                {v.id == 4 ? <LinkedinIcon /> : null}
                                                {v.id == 5 ? <YoutubeIcon /> : null}
                                            </label>
                                        </div>

                                    </div>
                                ))}
                            </div>
                            {errors.socialPlatform && <small className='text-red'>{errors.socialPlatform.message}</small>}

                            <div>
                                <Row>
                                    <Col xs={6}>
                                        <Button type="submit" className="btn-accept-post d-flex align-items-center justify-content-center text-center w-100 mt-5">
                                            <FaCheck />
                                            <span onClick={() => success("Post Accepted")} className='ms-2'>ACCEPT POST</span>
                                        </Button>
                                    </Col>

                                    <Col xs={6} className="mb-3">
                                        <Button onClick={handleDelete} type="submit" className="btn-reject-post d-flex align-items-center justify-content-center text-center w-100 mt-5">
                                            <FaTimes />
                                            <span className='ms-2'>DELETE POST</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyANqpbqOxMaZuvmhp7BE_8Y2om5fghSDQw"
})(PendingPostPopup);
