import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row, Carousel, Form, Dropdown } from 'react-bootstrap'
import StatsboxCard from '../components/dashboard/StatsboxCard'
import { TotalPostStatsIcon, SchedulePostStatsIcon, CalendarStreakStatsIcon, PendingIcon } from '../constants/svgs'
import { AiOutlineClockCircle } from "react-icons/ai"
import { NavLink } from 'react-router-dom'
import PendingPostCard from '../components/dashboard/PendingPostCard'
import { PendingPostData, PostManagementData, UserContext } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { PendingPost, UpcomingPost } from "../services/dashboard"
import { success, error } from "../constants/msg";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';

import { Bar, Chart, Line } from 'react-chartjs-2';
import faker from 'faker';
import Assets from '../constants/images'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css';

SwiperCore.use([Autoplay]);

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    scales: {
        x: {
            grid: {
                display: false
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
};

const labelsForPlatform = ['Facebook', 'Twitter', 'Instagram', 'Linkedin', 'Youtube'];
const labelsForMembers = ['Anthony', 'Emily', 'patricia', 'David', 'Chalres'];
const labelsForWeek = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
const labelsForMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const platformData = {
    labels: labelsForPlatform,
    datasets: [
        {
            label: '',
            data: labelsForPlatform.map(() => faker.datatype.number({ min: 0, max: 1500 })),
        },
    ],
};

export const memberData = {
    labels: labelsForMembers,
    datasets: [
        {
            label: '',
            data: labelsForMembers.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#2b2829',
        },
        {
            label: '',
            data: labelsForMembers.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: '#e09944',
        },
    ],
};

export const weekData = {
    labels: labelsForWeek,
    datasets: [
        {
            label: '',
            data: labelsForWeek.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: '2b2829',
            backgroundColor: '#2b2829',
        },
    ],
};

export const monthData = {
    labels: labelsForMonth,
    datasets: [
        {
            label: '',
            data: labelsForMonth.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            borderColor: '2b2829',
            backgroundColor: '#2b2829',
        },
    ],
};

function createGradient(ctx, area) {
    const colorStart = "#FEC053"
    const colorMid = "#F2203E"
    const colorEnd = "#B729A8"

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, colorStart);
    gradient.addColorStop(0.5, colorMid);
    gradient.addColorStop(1, colorEnd);

    return gradient;
}

const DashboardPage = () => {
    const chartRef = useRef(null);
    const [pendingPost, setPendingPost] = useContext(PendingPostData);
    // console.log(pendingPost, "pendingPost")
    const [postManagement, setPostManagement] = useState([]);
    const [userData, setUserData] = useContext(UserContext);


    var user_ID = userData.details.id //Login User ID




    const [filter, setFilter] = useState("0");
    const [handleOpen, setHandleOpen] = useState(false)
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    const [chartData, setChartData] = useState({
        datasets: [],
    });
    //Use Effect for the Pending Post.
    useEffect(() => {
        //Pending post API calling
        const getPending = async () => {
            try {
                const response = await PendingPost(user_ID);
                setPendingPost(response.data.data)
            } catch (e) {
                error(e.response.data.message)
            }
        }

        //Upcoming Scheduled post API calling 

        const upcomingPost = async () => {
            try {
                const response = await UpcomingPost(user_ID);
                // console.log(response, "scheduled post")
                setPostManagement(response.data.data)

            } catch (e) {
                error(e.response.data.message)
            }
        }

        getPending();
        upcomingPost();
    }, []);

    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }
        else {
            const chartData = {
                ...platformData,
                datasets: platformData.datasets.map(dataset => ({
                    ...dataset,
                    backgroundColor: [
                        '#4776b9',
                        '#1da1f2',
                        createGradient(chart.ctx, chart.chartArea),
                        '#3d83d9',
                        '#d40c18',
                    ],
                })),
            };
            setChartData(chartData);
        }
    }, [])

    const handleSelect = (ranges) => {
        setSelectionRange({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
            key: 'selection',
        })
    }

    const showDate = () => {
        let startDate = selectionRange.startDate.toString().slice(3, 7) + "," + selectionRange.startDate.toString().slice(10, 15)
        let endDate = selectionRange.endDate.toString().slice(3, 7) + "," + selectionRange.endDate.toString().slice(10, 15)
        let date = startDate + "-" + endDate
        return date
    }

    return (
        <div className='dashboard-page'>
            <Row>
                <Col xs={12} className="my-4">
                    <div>
                        <h5 className='text-yellow d-block d-md-none'>DASHBOARD</h5>
                        <h6 className='p-0 m-0'>Welcome Back, {userData.details.first_name} {userData.details.last_name}</h6>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12} xl={8}>
                    <Swiper
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 3000 }}
                        className="mb-2"
                        spaceBetween={20}
                        breakpoints={{
                            200: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                            1300: {
                                slidesPerView: 3,
                            },
                        }}
                    >

                        <SwiperSlide>
                            <StatsboxCard theme="light" icon={<TotalPostStatsIcon />} count="564" title="Total Posts" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatsboxCard theme="dark" icon={<SchedulePostStatsIcon />} count="125" title="Scheduled Posts" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <StatsboxCard theme="light" icon={<CalendarStreakStatsIcon />} count="365" title="Calendar Streaks" />
                        </SwiperSlide>
                    </Swiper>

                    <Row>
                        <Col xs={12} className="mb-4">
                            <div className="chart-container p-4">
                                {/* {
                                    handleOpen &&
                                    <div onClick={() => setHandleOpen(false)} className="overlay" style={{ backgroundColor: "transparent", position: "absolute", width: "100%", height: "100%", zIndex: "1" }}></div>
                                } */}
                                <div className='d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center'>
                                    <div className='d-flex align-items-center'>
                                        <img src={Assets.BarIcon} alt="" />
                                        <p className="p-0 m-0 ms-3">Post Created</p>
                                    </div>

                                    <div className='date-range-container d-flex flex-column flex-md-row align-items-md-center mt-3 mt-lg-0'>
                                        {/* <Form.Control readOnly onClick={() => setHandleOpen(!handleOpen)} className="mb-2 mb-md-0 me-0 me-md-2" value={showDate()} />
                                        {
                                            handleOpen &&
                                            <DateRangePicker
                                                ranges={[selectionRange]}
                                                onChange={handleSelect}
                                            />
                                        } */}

                                        <Dropdown className="calendar-dropdown mb-2 mb-md-0 me-0 me-md-2">
                                            <Dropdown.Toggle id="dropdown-basic">
                                                {showDate()}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <DateRangePicker
                                                    ranges={[selectionRange]}
                                                    onChange={handleSelect}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic">
                                                {
                                                    filter === "0" ?
                                                        "Filter By Platform" :
                                                        filter === "1" ?
                                                            "Filter By Members" :
                                                            filter === "2" ?
                                                                "Filter By Week" :
                                                                " Filter By Month"
                                                }
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item className="border-bottom py-2" href="#" onClick={() => setFilter("0")}>Filter By Platform</Dropdown.Item>
                                                <Dropdown.Item className="border-bottom py-2" href="#" onClick={() => setFilter("1")}>Filter By Members</Dropdown.Item>
                                                <Dropdown.Item className="border-bottom py-2" href="#" onClick={() => setFilter("2")}>Filter By Week</Dropdown.Item>
                                                <Dropdown.Item className="py-2" href="#" onClick={() => setFilter("3")}>Filter By Month</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                {
                                    filter === '0' ?
                                        <Chart className='mt-4' options={options} ref={chartRef} type='bar' data={chartData} /> :
                                        filter === '1' ?
                                            <Bar className='mt-4' options={options} data={memberData} /> :
                                            filter === '2' ?
                                                <Line className='mt-4' options={options} data={weekData} /> :
                                                <Line className='mt-4' options={options} data={monthData} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} xl={4}>
                    <div className='pending-post-container p-3'>
                        <div className='d-flex justify-content-between align-items-center border-bottom pb-3'>
                            <div className='d-flex align-items-center'>
                                <div className='clock-icon-container'>
                                    <AiOutlineClockCircle className='clock-icon' />
                                </div>
                                <h4 className='p-0 m-0 ms-3'>Pending Posts <span>({pendingPost.length})</span></h4>
                            </div>
                            <div>
                                <NavLink to="/pending-post">View All</NavLink>
                            </div>
                        </div>

                        <div className='pending-post-holder'>
                            {console.log(pendingPost, "pendingPosts")}

                            {pendingPost.length > 0 && pendingPost.map((data, index) => {
                                return (
                                    <PendingPostCard key={index} data={data} />
                                )
                            })

                            }

                        </div>

                        <div className='text-center mt-2'>
                            <NavLink to="/post-management-calendar">View Empty Slots</NavLink>
                        </div>
                    </div>
                </Col>

                <Col xs={12} className="mb-5 mb-sm-0">
                    <div className='slider-container'>
                        <h6 className='my-4'>Upcoming Scheduled Posts</h6>
                        <Swiper
                            className="mb-4"
                            spaceBetween={20}
                            breakpoints={{
                                200: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 2,
                                },
                                1200: {
                                    slidesPerView: 3,
                                },
                            }}
                        >

                            {postManagement.length > 0 && postManagement.map((data, index) => {
                                let a = index + 1;
                                return (
                                    <SwiperSlide key={index}>
                                        <PostManagementCard key={index} data={data} />
                                    </SwiperSlide>
                                )
                            })
                            }
                        </Swiper>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default DashboardPage