import React, { useState } from 'react'
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import OtpInput from "react-otp-input";
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { VerficationCode } from '../../services/auth'

const VerificationCodeForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(location.state.email);
    const [otp, setOtp] = useState("");
    const [errors, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { isValid } } = useForm({
        mode: "onChange"
    });

    const handleOtpChange = (otp) => {
        setOtp(otp)
        if (otp.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setError(false)
        }
    }


    const onSubmit = async () => {
        console.log(otp, "OTP code data")
        if (otp.length === 0) {
            setError(true)
            setErrorMessage("Please Enter OTP code")
        }
        else if (otp.length > 0 && otp.length < 4) {
            setError(true)
            setErrorMessage("OTP code is incomplete")
        }
        else {
            setError(false)
            try {
                const response = await VerficationCode(otp);
                success(response.data.message)
                setTimeout(() => {
                    navigate("/reset-password", { state: { otp: otp, email: email } })
                }, 1500)
            } catch (e) {
                error(e.response.data.message)
            }

        }
    }

    return (
        <section className='verification-code-section mt-5'>
            <ToastContainer />
            <span className='d-block text-center mb-5'>Check your email. We've sent you the PIN <br /> at {email}</span>
            <Row>
                <Col xs={12} md={12}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <OtpInput
                            className="otp-input"
                            value={otp}
                            onChange={(otp) => handleOtpChange(otp)}
                            numInputs={4}
                            isInputNum={true}
                            placeholder="----"
                            hasErrored={true}
                            focusStyle="focus"
                        />
                        {errors && <small className="text-red ms-1">{errorMessage}</small>}

                        <div className='text-center'>
                            <Button disabled={!isValid} type="submit" className="w-100 mt-5">SUBMIT</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </section>
    )
}

export default VerificationCodeForm