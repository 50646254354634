import React, { useState } from 'react'
import { Form, Button, InputGroup } from "react-bootstrap"
import { NavLink, useNavigate } from "react-router-dom"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { VALIDATIONS, VALIDATIONS_TEXT } from '../../constants/app-constants';
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import { Login, SetTokenLocalStorage, SetAuthUserLocalStorage } from '../../services/auth'
import { constant } from '../../utlis/constants'
import { UserContext } from "../../App";
import { base_url } from "../../constants/baseUrl";
import axios from "axios"
import { useContext } from 'react';

const SigninForm = () => {
    const [userData, setUserData] = useContext(UserContext);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange"
    });

    // const onSubmit = (data) => {
    //     const data1 = {
    //         "email": data.email,
    //         "password": data.password,
    //         "device_type": "web",
    //         "device_token": "aaaa",
    //         "role_id": 2
    //     }

    //     const response = axios.post(base_url + "/login", data1)
    //     .then((response) => {
    //         success("Signin Successfull")
    //         setTimeout(() => {
    //             navigate("/dashboard")
    //         }, 1500)
    //         console.log(response.data.data.user.access_token)
    //         localStorage.setItem('access_token',response.data.data.user.access_token)
    //         localStorage.setItem('user',JSON.stringify(response.data.data.user))
    //       })
    //     .catch(function (error) {
    //         if (error.response) {
    //             showError(error.response.data.message)
    //             console.log(error.response.status);
    //         }
    //     });
    // }

    const onSubmit = async (data) => {
        const { email, password } = data;
        try {
            const response = await Login(email, password, constant.DEVICE_TYPE, constant.DEVICE_TOKEN);
            success(response.data.message)
            setTimeout(() => {
                navigate("/dashboard", { replace: true })
            }, 1500);
            SetTokenLocalStorage(response.data.data.user.access_token);
            SetAuthUserLocalStorage(response.data.data.user);
            setUserData((response.data.data.user))
        } catch (e) {
            error(e.response.data.message)
        }
    }

    function showError(text) {
        error(text);
    }

    return (
        <section className='signin-section mt-3 mt-md-5'>
            <ToastContainer />
            <div className='d-block d-md-none text-center mb-4'>
                <h1 className='welcome-text'>WELCOME BACK TO RODEO</h1>
            </div>
            <span className='d-block text-center mb-5'>Let's create your account <br /> Enter your details to setup your account.</span>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup className='mt-3'>
                    <Form.Control
                        name="email"
                        type="email"
                        placeholder="Email *"
                        {...register("email",
                            {
                                maxLength: {
                                    value: VALIDATIONS.EMAIL,
                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                }
                            })
                        }
                    />
                </InputGroup>
                {errors.email && <small className='text-red'>{errors.email.message}</small>}

                <InputGroup className='mt-3'>
                    <Form.Control
                        name="password"
                        className="password-input"
                        placeholder="Password *"
                        type={!showPassword ? "password" : "text"}
                        {...register("password",
                            {
                                minLength: {
                                    value: VALIDATIONS.PASSWORD_MIN,
                                    message: VALIDATIONS_TEXT.PASSWORD_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.PASSWORD_MAX,
                                    message: VALIDATIONS_TEXT.PASSWORD_MAX
                                },
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                },
                            })
                        }

                    />
                    <InputGroup.Text>
                        {
                            !showPassword ?
                                <AiOutlineEye className="eye-icon" onClick={() => setShowPassword(!showPassword)} /> :
                                <AiOutlineEyeInvisible className="eye-icon" onClick={() => setShowPassword(!showPassword)} />
                        }
                    </InputGroup.Text>
                </InputGroup>
                {errors.password && <small className='text-red'>{errors.password.message}</small>}


                <div className='text-center mt-4'>
                    <NavLink className='d-block' to="/forgot-password">FORGOT PASSWORD</NavLink>
                    <Button type="submit" className="w-100 mt-5">SIGN IN</Button>
                </div>
            </Form>

            <div className='text-center mt-5'>
                <span>or continue with</span>
                <div className='d-flex align-items-center mt-4'>
                    <Button className='d-flex align-items-center justify-content-center btn-google w-100 me-3'><FcGoogle className='me-2' />Sign in</Button>
                    <Button className='d-flex align-items-center justify-content-center btn-facebook w-100'><FaFacebookF className='me-2' />Sign in</Button>
                </div>
            </div>
        </section>
    )
}

export default SigninForm