import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './Navigation';
import Assets from './constants/images';
import { GetAuthUserLocalStorage } from './services/auth';
import Loader from './components/loader/Loader';

const ChoosePlanData = createContext();
const PostManagementData = createContext();
const PendingPostData = createContext();
const SelectedPlanData = createContext();

// popup context
const CreatePostPopupContext = createContext();
const NotificationPopupContext = createContext();
const ProfilePopupContext = createContext();
const FilterPopupContext = createContext();
const PendingSubmissionPopupContext = createContext();


//User
const UserContext = createContext();

const App = () => {

  const [choosePlan, setChoosePlan] = useState([
    {
      price: "Individual:  $70/mo",
      description: "For the solopreneur who’s fed up with the time and effort required to keep up on social media.",
      title: "1 Manager (Web & App) Collaborators not included",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️View Reporting & Post Engagement"]
    },
    {
      price: "Team:  $250/mo",
      description: "Collaborators can submit content via the iOS app. Turn off auto-post to review submissions before being published.",
      title: "1 Manager  (Web & App) 10 Collaborators (App)",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️Team Leaderboard", "View Reporting & Post Engagement"]
    },
    {
      price: "Marketing Managers:  $  /mo",
      description: "Collaborators can submit content via the iOS app. Turn off auto-post to review submissions before being published.",
      title: "5 Manager  (Web & App) 10 Collaborators (App)",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️Team Leaderboard", "️View Reporting & Post Engagement"]
    },
  ])

  const [postManagement, setPostManagement] = useState([]);

  const [pendingPost, setPendingPost] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(
    {
      price: "Individual:  $70/mo",
      description: "For the solopreneur who’s fed up with the time and effort required to keep up on social media.",
      title: "1 Manager (Web & App) Collaborators not included",
      list: ["Photo, Video, Text, and Link Submissions", "Location & Account Tagging(as available by platform)", "Manage / Edit Submissions before Posting(or AutoPost)", "Schedule Posts or Create Post Calendars", "Streaks & Reminder Notifications", "️View Reporting & Post Engagement"]
    }
  )

  const [userData, setUserData] = useState(GetAuthUserLocalStorage());


  const [handleCreatePostPopup, setHandleCreatePostPopup] = useState(false);
  const [handleNotificationPopup, setHandleNotificationPopup] = useState(false);
  const [handleProfilePopup, setHandleProfilePopup] = useState(false);
  const [handleFilterPopup, setHandleFilterPopup] = useState(false);
  const [handlePendingSubmissionPopup, setHandlePendingSubmissionPopup] = useState(false);

  return (
  
    <ChoosePlanData.Provider value={[choosePlan, setChoosePlan]}>
      <PostManagementData.Provider value={[postManagement, setPostManagement]}>
        <PendingPostData.Provider value={[pendingPost, setPendingPost]}>
          <CreatePostPopupContext.Provider value={[handleCreatePostPopup, setHandleCreatePostPopup]}>
            <NotificationPopupContext.Provider value={[handleNotificationPopup, setHandleNotificationPopup]}>
              <ProfilePopupContext.Provider value={[handleProfilePopup, setHandleProfilePopup]}>
                <FilterPopupContext.Provider value={[handleFilterPopup, setHandleFilterPopup]}>
                  <SelectedPlanData.Provider value={[selectedPlan, setSelectedPlan]}>
                    <PendingSubmissionPopupContext.Provider value={[handlePendingSubmissionPopup, setHandlePendingSubmissionPopup]}>
                      <UserContext.Provider value={[userData, setUserData]}>
                        <BrowserRouter>
                          <Navigation />
                        </BrowserRouter>
                      </UserContext.Provider>
                    </PendingSubmissionPopupContext.Provider>
                  </SelectedPlanData.Provider>
                </FilterPopupContext.Provider>
              </ProfilePopupContext.Provider>
            </NotificationPopupContext.Provider>
          </CreatePostPopupContext.Provider>
        </PendingPostData.Provider>
      </PostManagementData.Provider>
    </ChoosePlanData.Provider>
  )
}

export default App
export { UserContext, ChoosePlanData, PostManagementData, PendingPostData, SelectedPlanData, CreatePostPopupContext, NotificationPopupContext, ProfilePopupContext, FilterPopupContext, PendingSubmissionPopupContext }
