import Spinner from 'react-bootstrap/Spinner';

function Loader() {
    return (
        <>
            <div className='loader-wrapper'>
                <Spinner animation="border" variant="secondary" />
            </div>

        </>
    );
}

export default Loader;