import React, { useState, useEffect, useContext } from 'react'
import { DoneIcon, PendingIcon, RejectIcon, FacebookIcon, TwitterIcon, InstagramIcon, LinkedinLogoSmall, YoutubeIcon } from '../../constants/svgs'
import Assets from '../../constants/images'
import { Row, Col, Carousel, NavDropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { success, error } from "../../constants/msg";
import { DeletePost } from "../../services/posts";
import { useNavigate, useParams } from "react-router-dom";


const SinglePostManagementCard = ({ handleClickEditPostPopup, singlePost }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [hashValues, setHashValues] = useState([]);
    const [strWithoutHash, setStrWithouthash] = useState();

    function findHashtags(searchText) {
        var regexp = /\B\#\w\w+\b/g
        let hashvalues = searchText.match(regexp);
        let str = searchText.replace(regexp, '');
        if (hashvalues) {
            setHashValues(hashvalues);
            setStrWithouthash(str)
        } else {
            return false;
        }
    }

    useEffect(() => {
       if(singlePost){
        findHashtags(singlePost?.text);
       }
    },[singlePost])

console.log("sdf",singlePost)
    const deletePost = async () => {
        try {
            const response = await DeletePost(singlePost.id);
            success(response.data.message)
            setTimeout(() => {
                navigate("/dashboard")
            }, 1500)

        } catch (e) {
            error(e.response.data.message)
        }
    }

    return (
        <Col xs={12} sm={12} md={8} lg={6} className="mb-4">

            <div className='post-management-card single-post-management-card p-3 pt-2'>
                <div className='d-flex justify-content-end'>
                    <NavDropdown title={<BsThreeDots className='icon-dots' />} id="basic-nav-dropdown">

                        <NavDropdown.Item>
                            <div className='operation-box'>
                                <div className='w-100'>
                                    <div className="hover-div border-bottom">
                                        <button className='w-100 text-start' onClick={() => handleClickEditPostPopup()}>Edit Post </button>
                                    </div>

                                    <div className="hover-div border-bottom">
                                        <button className='w-100 text-start'>Approve Post</button>
                                    </div>

                                    <div className="hover-div border-bottom">
                                        <button className='w-100 text-start'>Reject Post</button>
                                    </div>

                                    <div className="hover-div">
                                        <button className="w-100 text-start" onClick={() => deletePost()}>Delete Post</button>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown.Item>
                    </NavDropdown>
                </div>

                <div className='mb-3'>
                    <div className='d-flex align-items-center'>
                        <DoneIcon />
                        <small className='ms-3'>{singlePost?.schedule_time}</small>
                    </div>
                </div>

                <div className='avatar-container d-flex align-items-center '>
                    <img src={singlePost?.user?.details?.image_url} alt="Avatar" className='rounded-circle' />
                    <div className='d-flex flex-column ms-2'>
                        <span>{singlePost?.user?.name}</span>
                        <small>{singlePost?.elapsed_time}</small>
                    </div>
                </div>

                <div className='mt-3'>
                    <p>{strWithoutHash} {hashValues?.map((item, key) => (<span className='me-1' key={key}>{item}</span>))}</p>

                    <div className='footer d-flex flex-xl-row flex-column align-items-xl-center justify-content-between'>
                        <div className='mt-3 mt-xl-0 icon-div d-flex align-items-center'>
                            {singlePost?.platforms.length > 0 && singlePost?.platforms.map((d, i) => {
                                if (d.id === 1) {
                                    return <div className='ms-xl-2'><FacebookIcon /></div>
                                }
                                else if (d.id === 2) {
                                    return <div className='ms-xl-2 mx-2 mx-xl-0'><TwitterIcon /></div>
                                }
                                else if (d.id === 3) {
                                    return <div className='ms-xl-2'><InstagramIcon /></div>
                                }
                                else if (d.id === 4) {
                                    return <div className='ms-xl-2'><LinkedinLogoSmall /></div>
                                }
                                else {
                                    return <div className='ms-xl-2'><YoutubeIcon /></div>
                                }

                            })}
                        </div>
                    </div>

                    <div className='mt-4'>
                        <Carousel>
                            {singlePost?.media?.length > 0 && singlePost.media.map((d, i) => {
                                return (<Carousel.Item key={i}>
                                    <img className="w-100" src={d.url} alt="First slide" />
                                </Carousel.Item>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
            </div>
        </Col >
    )
}

export default SinglePostManagementCard