import React, { useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { PostManagementData } from '../App'
import PostManagementCard from '../components/dashboard/PostManagementCard';
import { CalendarIcon, SwitchToPostManagementIcon, ToggleIcon } from '../constants/svgs';
import { FilterPopupContext, UserContext } from '../App'
import FilterSearchPopup from '../components/dashboard/FilterSearchPopup';
import { AllPost } from "../services/posts";
import { error } from "../constants/msg";
import Loader from '../components/loader/Loader';

const PostManagementPage = () => {
    const [loading, setLoading] = useState(false);
    const [postManagement, setPostManagement] = useContext(PostManagementData);
    const [handleFilterPopup, setHandleFilterPopup] = useContext(FilterPopupContext);
    const [userData, setUserData] = useContext(UserContext);
    var user_ID = userData.details.id;
    useEffect(() => {
        const getAllPost = async () => {
            try {
                const response = await AllPost(user_ID);
                setPostManagement(response.data.data)
                setLoading(true)
            } catch (e) {
                error(e.response.data.message)
            }
        }
        getAllPost()
    })
    return (
        <>
            {handleFilterPopup && <FilterSearchPopup />}
            <div className="post-management-page">
                <Row className="mt-4">
                    <Col xs={12} className="d-block d-md-none mb-3">
                        <h5 className='text-yellow'>POST MANAGEMENT</h5>
                    </Col>

                    <Col xs={12}>
                        <div className="navigation-container d-flex align-items-center text-center justify-content-end">
                            <NavLink className={({ isActive }) => isActive ? "active me-3" : "me-3"} to='/post-management'>
                                <SwitchToPostManagementIcon />
                            </NavLink>
                            <NavLink className="me-3" to='/post-management-calendar'><CalendarIcon /></NavLink>
                            <Button onClick={() => setHandleFilterPopup(true)} className={`btn-filter ${handleFilterPopup && "active"}`}><ToggleIcon /></Button>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4 mb-5 mb-sm-0">
                    {loading ? (postManagement.map((data, index) => {
                        return (
                            <Col key={index} xs={12} sm={12} md={6} lg={4} className="mb-4">
                                <PostManagementCard data={data} />
                            </Col>
                        )
                    })) : (<Loader />)}
                </Row>
            </div>
        </>
    )
}

export default PostManagementPage