import axios from 'axios';
import { constant, apiEndPoints } from '../utlis/constants';
import { GetTokenLocalStorage } from './auth';

// get all notifications

export const getAllNotification = async () => {
    const resp = await axios.get(`${constant.BASE_URL + apiEndPoints.notifications}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}

//pull Notification

export const putNotification = async (notification_ID) => {
    const resp = await axios.put(`${constant.BASE_URL + apiEndPoints.notifications}/${notification_ID}`, {
        'headers': {
            'Authorization': 'Bearer ' + GetTokenLocalStorage()
        }
    }

    );
    return resp
}